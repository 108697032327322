import { CURATION_STATUS } from '../../common/constants'

import suggestCuration from './suggestCuration'
import suggestVariousText from './suggestVariousText'

const aiFieldsToCurateMap = new Map([
  [
    CURATION_STATUS.MISC_AND_CHOICE.value,
    {
      suggest: suggestVariousText,
      suggestButtonText: 'Suggest misc and choice text',
    },
  ],
  [
    CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
    {
      suggest: suggestVariousText,
      isRetry: true,
      suggestButtonText: 'Retry misc and choice text',
    },
  ],
  [
    CURATION_STATUS.CURATION.value,
    {
      suggest: suggestCuration,
      suggestButtonText: 'Suggest curation',
    },
  ],
  [
    CURATION_STATUS.CURATION_CONFIRMATION.value,
    {
      suggest: suggestCuration,
      isRetry: true,
      suggestButtonText: 'Retry curation',
    },
  ],
  [
    CURATION_STATUS.QA.value,
    {
      suggest: suggestCuration,
      isRetry: true,
      suggestButtonText: 'Retry curation',
    },
  ],
  [
    CURATION_STATUS.QQA_CONFIRMATION.value,
    {
      suggest: suggestCuration,
      isRetry: true,
      suggestButtonText: 'Retry curation',
    },
  ],
  [
    CURATION_STATUS.DONE.value,
    {
      suggest: suggestCuration,
      isRetry: true,
      suggestButtonText: 'Retry curation',
    },
  ],
])

export const getAiSuggestionStepForStatus = status => {
  const step = aiFieldsToCurateMap.get(status)
  if (step) {
    return step
  }

  return null
}
