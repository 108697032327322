import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Tooltip } from 'antd'
import { every, get, includes, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious, useQueryParams } from '../../../../core/hooks'

import { CURATION_STATUS, EVENT_TYPES } from '../../../../common/constants'
import { LoadingContext } from '../../../../common/contexts'
import { useCachedBrand, useCurationMetadata } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { useBrandCurationMetadata } from '../../../../brands/hooks'
import { useBrandDish, useSaveBrandDish } from '../../../hooks'
import { removeUnsavedDish } from '../../../redux'
import { getAiSuggestionStepForStatus } from '../../../services'

const styles = {
  stepButton: {
    marginRight: '5px',
  },
}

const DishAiSuggestionsActionsButton = ({
  disableSuggest,
  disableSuggestMessage,
  refetchBrand,
}) => {
  const { loadingCount, startLoading, stopLoading } = useContext(LoadingContext)
  const { brandId } = useParams()
  const { selectedDishId } = useQueryParams()
  const { dish, unsavedDish } = useBrandDish(selectedDishId)
  const brand = useCachedBrand()
  // It's used for main ingredients suggestions
  const { ingredientOptions, dishTypeOptions, dietOptions } =
    useCurationMetadata()
  const { menuTitleOptions } = useBrandCurationMetadata()
  const prevUnsavedDish = usePrevious(unsavedDish)
  const dispatch = useDispatch()
  const dishStatus = dish?.status

  const { save: saveDish } = useSaveBrandDish({
    brandId,
    dishId: selectedDishId,
    originalDish: dish,
  })

  const suggestionStep = useMemo(
    () => getAiSuggestionStepForStatus(dishStatus),
    [dishStatus],
  )

  const handleSuggestions = useCallback(
    async (step, additionalInformation = {}) => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.SUGGEST,
        { for: dishStatus },
      )
      startLoading()

      const suggestionResult = await step.suggest({
        dish,
        additionalInformation: {
          ...additionalInformation,
          menuTitleOptions,
          dietOptions,
          ingredientOptions,
          dishTypeOptions,
          brand,
        },
      })

      stopLoading()
      if (!isEmpty(suggestionResult)) {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_SUGGESTIONS,
          { for: dishStatus },
        )
        saveDish({
          modifiedDish: {
            ...(unsavedDish || {}),
            ...(suggestionResult.unsavedDish || {}),
          },
          aiSuggestions: suggestionResult.aiSuggestions,
          callback: async () => {
            refetchBrand()
            dispatch(removeUnsavedDish(selectedDishId))
          },
        })
      }
    },
    [
      dishStatus,
      startLoading,
      dish,
      menuTitleOptions,
      dietOptions,
      ingredientOptions,
      dishTypeOptions,
      brand,
      stopLoading,
      saveDish,
      unsavedDish,
      refetchBrand,
      dispatch,
      selectedDishId,
    ],
  )

  const handleSuggestClick = useCallback(
    () => handleSuggestions(suggestionStep),
    [handleSuggestions, suggestionStep],
  )

  const handleRetry = useCallback(
    async (statusToRetry, additionalInformation = {}) => {
      const stepToRetry = getAiSuggestionStepForStatus(statusToRetry)
      await handleSuggestions(stepToRetry, additionalInformation)
    },
    [handleSuggestions],
  )

  // Trigger suggestions using shortcuts
  useHotkeys(
    'alt+s',
    () => {
      if (!isNil(suggestionStep) && !disableSuggest) {
        handleSuggestions(suggestionStep)
      }

      return false
    },
    {},
    [handleSuggestions, suggestionStep],
  )

  // Trigger ingredients suggestions when dish type changes or diets change
  useEffect(() => {
    const { dishTypeId: prevDishTypeId, dietIds: prevDietIds } = prevUnsavedDish
    const { dishTypeId, dietIds } = unsavedDish

    if (
      !loadingCount &&
      includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
          CURATION_STATUS.DONE.value,
        ],
        dishStatus,
      ) &&
      dishTypeId &&
      dishTypeId !== prevDishTypeId &&
      !disableSuggest
    ) {
      handleRetry(CURATION_STATUS.CURATION_CONFIRMATION.value, {
        dishType: { id: dishTypeId },
        isRefetchFromDishType: true,
        isDishIgnored: get(dish, 'dishType.isIgnored'),
      })
    }

    if (
      !loadingCount &&
      includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
          CURATION_STATUS.DONE.value,
        ],
        dishStatus,
      ) &&
      dietIds &&
      (!prevDietIds ||
        !every(dietIds, dietId => includes(prevDietIds, dietId)) ||
        !every(prevDietIds, prevDietId => includes(dietIds, prevDietId))) &&
      !disableSuggest
    ) {
      handleRetry(CURATION_STATUS.CURATION_CONFIRMATION.value, {
        dietIds,
        isRefetchFromDietIds: true,
      })
    }
  }, [
    dish,
    prevUnsavedDish,
    unsavedDish,
    handleRetry,
    selectedDishId,
    suggestionStep,
    loadingCount,
    disableSuggest,
    dishStatus,
  ])

  if (isNil(suggestionStep)) {
    return null
  }

  if (isNil(get(dish, 'id'))) {
    return null
  }

  return (
    <Tooltip title={disableSuggestMessage} placement="bottom">
      <Button
        size="small"
        type="primary"
        onClick={handleSuggestClick}
        loading={loadingCount > 0}
        disabled={
          isEmpty(dish) ||
          !isEmpty(unsavedDish) ||
          isNil(suggestionStep) ||
          disableSuggest
        }
        style={styles.stepButton}
      >
        {suggestionStep.suggestButtonText}
      </Button>
    </Tooltip>
  )
}

DishAiSuggestionsActionsButton.propTypes = {
  disableSuggest: PropTypes.bool,
  disableSuggestMessage: PropTypes.string,
  refetchBrand: PropTypes.func.isRequired,
}

DishAiSuggestionsActionsButton.defaultProps = {
  disableSuggest: false,
  disableSuggestMessage: undefined,
}

export default memo(DishAiSuggestionsActionsButton)
